<template>
  <section>
    <!-- Antes verificaba si la solicitud ya habia sido aprobada por ucp, ahora verifica si el proveedor ya fue aprobado -->
    <!-- pasoSolicitud?.id !== 4 -->
    <v-row v-if="proveedorInfo?.estado_proveedor_web?.id == 1">
      <v-col cols="12">
        <v-btn
          :disabled="habilitarVerificacion"
          @click="verificarDialog = true"
          class="px-0"
          text
          color="green"
        >
          <v-icon color="green" small class="mr-2">mdi-send</v-icon>
          Verificar proveedor
        </v-btn>
        <v-btn
          @click="descartarDialog = true"
          class="px-0 mx-5"
          text
          color="red"
        >
          <v-icon small class="mr-2" color="red">mdi-cancel</v-icon>
          Descartar proveedor
        </v-btn>
        <v-btn @click="observarDialog = true" text color="primary" class="px-0">
          <v-icon color="primary-text" small class="mr-2"
            >mdi-message-reply-text</v-icon
          >
          Marcar con observación
        </v-btn>
      </v-col>
    </v-row>

    <ConfirmationDialogComponent
      :show="verificarDialog"
      btnConfirmar="Aprobar"
      title="¿Desea aprobar proveedor?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      @close="verificarDialog = false"
      @confirm="changeStatusProvider(2)"
    />

    <ConfirmationDialogComponent
      :show="descartarDialog"
      btnConfirmar="Aceptar"
      title="¿Desea descartar proveedor?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      @close="descartarDialog = false"
      @confirm="changeStatusProvider(3)"
    />

    <ConfirmationDialogComponent
      :show="observarDialog"
      btnConfirmar="Aceptar"
      showMotivoCancelar
      title="Marcar con observación"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      @close="observarDialog = false"
      @input="setMotivo"
      @confirm="changeStatusProvider(4)"
    />

    <div class="my-8">
      <p class="no-margin">Proveedor</p>
      <p class="no-margin text--secondary">{{ proveedorInfo.nombre }}</p>
    </div>

    <div class="my-8 py-8 mx-4">
      <p>Listado de insumos</p>
      <InsumosTableComponent :headers="headers" />
    </div>

    <div class="my-8">
      <p class="">Documentos del proveedor</p>
      <v-alert
        v-if="providerDocs.length == 0"
        color="grayMinsal"
        border="left"
        colored-border
        icon="mdi-information-outline"
        dense
      >
        Para poder verificar el proveedor es necesario cargar al menos un
        documento
      </v-alert>
      <DocumentosCargadosComponent
        @saveDoc="saveDocument"
        @reloadDoc="getDocumentosProveedor()"
        :headers="headersDocs"
        :items="providerDocs"
      />
    </div>

    <div class="my-8">
      <v-btn color="secondary" @click="regresar()" text>Volver</v-btn>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.no-margin {
  margin: 0;
}
</style>

<script>
import { mapState, mapActions } from "vuex";
import ConfirmationDialogComponent from "@/components/ConfirmationDialogComponent.vue";
import DocumentosCargadosComponent from "@/components/DocumentosCargadosComponent.vue";
import InsumosTableComponent from "@/components/InsumosTableComponent.vue";

export default {
  name: "verificacionproveedorView",
  components: {
    ConfirmationDialogComponent,
    DocumentosCargadosComponent,
    InsumosTableComponent,
  },
  data: () => ({
    verificarDialog: false,
    descartarDialog: false,
    observarDialog: false,
    headers: [
      {
        text: "Correlativo",
        value: "correlativo",
        align: "center",
      },
      {
        text: "Mercancía",
        align: "center",
        value: "nombre_origen_compra_en_linea",
      },
      {
        text: "Especificación",
        align: "center",
        value: "especificacion_compra_en_linea",
      },
      {
        text: "Unidad de medida",
        align: "center",
        value: "unidad_medida",
      },

      {
        text: "Total ($)",
        align: "center",
        value: "total",
      },
      {
        text: "Estado",
        align: "center",
        value: "estado",
      },
      {
        text: "Acciones",
        value: "ver",
        align: "center",
        sortable: false,
      },
    ],
    motivoObservacion: "",
    headersDocs: [
      {
        text: "Documento",

        value: "nombre",
      },
      {
        text: "Fecha carga",
        align: "center",
        value: "fecha_carga",
      },

      {
        text: "Acciones",
        align: "center",
        value: "acciones",
        sortable: false,
      },
    ],
    providerDocs: [],
  }),
  computed: {
    ...mapState("agregarInsumo", [
      "selectedProvider",
      "id_solicitud",
      "insumosList",
      "proveedorInfo",
    ]),
    ...mapState("comprasModule", ["pasoSolicitud"]),
    habilitarVerificacion() {
      return (
        // verifica que exista por lo menos un insumo
        this.insumosList.length == 0 ||
        // verifica que exista por lo menos un documento
        this.providerDocs.length == 0 ||
        // verifica que exista no exista un insumo con estado pendiente u observado
        this.insumosList.filter(
          (target) =>
            target.id_estado == 1 || // pendiente
            target.id_estado == 8 // observado
        ).length > 0 ||
        // verifica si tdos los insumos estan descartados
        this.insumosList.every(
          (target) => target.id_estado == 10
          ) ||
          // verifica que exista por lo menos un insumo con estado aprobado
        !this.insumosList.filter(
          (target) =>
            target.id_estado == 9
        ).length > 0
      );
      //
    },
  },
  methods: {
    ...mapActions("agregarInsumo", ["getInsumosList"]),
    regresar() {
      this.$router.push({
        name: "compras",
        params: {
          tipo: Number(this.$route.params.tipo),
          id_solicitud: this.id_solicitud,
        },
      });
    },
    setMotivo(value) {
      this.motivoObservacion = value;
    },
    async changeStatusProvider(id_estado) {
      let body = {
        id_solicitud: this.id_solicitud,
        id_estado_proveedor_web: id_estado,
        observacion: this.motivoObservacion,
        id_paso_actual: this.pasoSolicitud.id,
      };
      const { status } =
        await this.services.SolicitudCompra.putSolicitudesProveedoresWeb(
          this.selectedProvider,
          body
        );

      if (status == 204) {
        this.$router.push({
          name: "compras",
          params: {
            tipo: Number(this.$route.params.tipo),
            id_solicitud: this.id_solicitud,
          },
        });
      }
    },
    async saveDocument(value) {
      let formData = new FormData();
      formData.append("nombre", value.nombreArchivo);
      formData.append("documento", value.archivo);
      const { status } =
        await this.services.SolicitudCompra.postSolicitudesProveedorDocs(
          this.selectedProvider,
          this.id_solicitud,
          formData
        );
      if (status == 201) {
        this.getDocumentosProveedor();
        this.temporalAlert({
          show: true,
          message: "Documento agregado exitosamente",
          type: "success",
        });
      }
    },
    async getDocumentosProveedor() {
      const { status, data } =
        await this.services.SolicitudCompra.getSolicitudesProveedorDocs(
          this.selectedProvider,
          this.id_solicitud
        );
      if (status == 200) {
        this.providerDocs = data;
      }
    },
  },
  created() {
    let props = {
      id_solicitud: this.id_solicitud,
      proveedor: this.$route.query?.proveedor,
    };
    this.getInsumosList(props);
    this.getDocumentosProveedor();
  },
};
</script>
