<template>
  <v-row>
    <v-col v-if="
      $route.params.tipo == 2 && selectedUnidad.cargo[0].nombre !== 'JEFE UCP'
    " cols="3" class="py-0 mx-0">
      <v-select outlined auto-select-first :items="filtroObs" item-text="obs" item-value="id_obs"
        no-data-text="No hay datos para mostrar" dense clearable @change="updateObsFilter" label="OBS"
        style="width: 400px" />
    </v-col>
    <v-col cols="12" class="pr-8 py-0">
      <data-table-component :headers="headers" :items="insumosList" v-models:select="perPageModel"
        v-models:pagina="pageModel" :total_registros="insumoFilters.total_rows" @paginar="paginar"
        mobile_breakpoint="950">
        <template v-slot:[`item.correlativo`]="{ item }">
          {{
  firstElement(
    insumosList
          .map((col) => col.id)
    .reverse()
    .indexOf(item.id) + 1
          ) || ""
          }}
        </template>
        <template v-slot:[`item.detalle`]="{ item }">
          {{ item.detalle?.map((col) => col.nombre).join(", ") || "" }}
        </template>
        <template v-slot:[`item.url_origen_compra_en_linea`]="{ item }">
          <a class="secondary--text" :href="item.url_origen_compra_en_linea" target="blank_" v-text="'Ver artículo'" />
        </template>
        <template v-slot:[`item.unidad_medida`]="{ item }">
          {{
           item.unidad_medida || item.unidad_medida_proveedor

          }}
        </template>
        <template v-slot:[`item.total`]="{ item }">
          {{
            Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(desdeUCP ? item.total_aprobado : item.total)
          }}
        </template>
        <template v-slot:[`item.precio_unitario`]="{ item }">
          {{
            Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(item.precio_unitario)
          }}
        </template>
        <template v-slot:[`item.cantidad`]="{ item }">
          {{
            item.cantidad
          }}
        </template>
        <template v-slot:[`item.cantidad_aprobar`]="{item}">
          {{
            item.solicitud_compra_detalle_obs.reduce((acumulador,actual)=> acumulador + actual.cantidad_aprobar,0) || 0
          }}
        </template>
         <template v-slot:[`item.total_aprobar`]="{ item }">
          {{
             Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(item.total_aprobado)
          }}
        </template>
        <template v-slot:[`item.estado`]="{ item }">
          <v-chip label :dark="item.estado.includes('Descartado')" :color="item.color">
            {{ item.estado || "" }}
          </v-chip>
        </template>
        <template v-slot:[`item.accion`]="{ item }">
          <v-tooltip left v-if="
            !permitirVerInsumo && validarIdEstado(item.id_estado)
          ">
            <template v-slot:activator="{ on, attrs }">
              <v-btn :disabled="item.estado == 'Descartado'" v-bind="attrs" v-on="on" icon small @click="editar(item)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span> Editar </span>
          </v-tooltip>
          <v-tooltip right v-if="permitirVerInsumo">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon small @click="editar(item)">
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span> Ver </span>
          </v-tooltip>
          <v-tooltip right left v-if="pasoSolicitud?.id  == 1 && item.estado == 'Pendiente'">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon small @click="eliminar(item)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span> Eliminar </span>
          </v-tooltip>

        </template>
        <template v-slot:[`item.ver`]="{ item }">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon small @click="editar(item)">
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span> Ver </span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.verDetalle`]="{ item }" v-if="$route.params.tipo == 2">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon small @click="verDetalle(item)">
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span> Ver </span>
          </v-tooltip>
        </template>
      </data-table-component>
    </v-col>
    <dialogoConfirmacion :show="modalEliminar" title="¿Deseas remover el insumo?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles." btnConfirmar="Aceptar"
      @close="modalEliminar = false" @confirm="eliminarInsumo()" />
  </v-row>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import dialogoConfirmacion from "./ConfirmationDialogComponent.vue";
import DataTableComponent from "@/components/DataTableComponent.vue";
export default {
  name: "ListadoInsumosTable",
  components: {
    dialogoConfirmacion,
    DataTableComponent,
  },
  props: {
    headers: { type: Array, default: () => [] },
    desdeUCP: { type: Boolean, default: false },
  },
  data() {
    return {
      Fondos: [],
      modalEliminar: false,
      id: null,
      modalEnviar: false,
      obs: [],
      sortDesc: false,
      filtroObs: [],
    };
  },
  methods: {
    ...mapActions("agregarInsumo", [
      "getInsumosList",
      "getFondosAsignados",
      "getObjetosPresupuestarios",
    ]),
    ...mapActions("catalogo", ["obtenerProducto"]),
    ...mapMutations("agregarInsumo", [
      "setPage",
      "setPerPage",
      "setInsumoEditable",
      "setInsumoData",
      "setOBSInsumoFilter",
      "setFondoFilters",
      "setEstadoInsumo",
      "setObjetoPresupuestario",
      "setIdEstadoInsumo",
    ]),
    filterInsumosBy(fondo) {
      this.setFondoFilters(fondo.id_fnto_linea_unidad);
      this.getInsumosList(this.id_solicitud);
    },
    agregarInsumo() {
      if (this.$route.params.tipo === 1 || this.$route.params.tipo === 3) {
        this.$router.push({
          name: "agregar-insumo-compra",
          params: { tipo: this.$route.params.tipo },
        });
      }
      if (this.$route.params.tipo === 2) {
        this.$router.push("/proceso-solicitud-compra/catalogo-de-compras");
      }
    },
    async editar(item) {
      this.setInsumoEditable(true);
      this.setEstadoInsumo(item.estado);
      this.setIdEstadoInsumo(item.id_estado);
      this.setObjetoPresupuestario(item.detalle_obs);
      // const { status, data } =
      //   await this.services.SolicitudCompra.getSolicitudesCompraInsumo(item.id);
      // if (status === 200) {
      //   this.setInsumoData(data);

      //   if (this.$route.params.tipo === 2) {
      //     await this.obtenerProducto(item.id_presentacion);
      //   }

      this.$router.push({
        name: "agregar-insumo-detalles",
        params: {
          tipo: Number(this.$route.params.tipo),
          idSolicitud: this.id_solicitud,
          idInsumo: item.id,
        },
        query: {
          proveedor: this.$route.query?.proveedor,
          compra: this.$route.params.id_solicitud,
        },
      });
      // }
    },
    eliminar(item) {
      this.id = item.id;
      this.modalEliminar = true;
    },
    async eliminarInsumo() {
      const { status } =
        await this.services.SolicitudCompra.deleteSolicitudCompraInsumo(
          this.id
        );

      if (status == 204) {
        this.temporalAlert({
          show: true,
          message: "Insumo eliminado con éxito",
          type: "success",
        });
        await this.getFondosAsignados({
          id_solicitud: this.id_solicitud,
          id_unidad: this.selectedUnidad.id,
        });
        this.$emit('emitUpdateProv');
        await this.getInsumosList(this.id_solicitud);
        this.modalEliminar = false;
      }
    },
    validarIdEstado(id) {
      const idsEditar = [1, 2, 5, 8];
      return idsEditar.includes(id);
    },
    paginar(filtros) {
      const { cantidad_por_pagina, pagina } = filtros;
      this.insumoFilters.page = pagina;
      this.insumoFilters.per_page = cantidad_por_pagina;
      this.getInsumosList(this.id_solicitud);
    },
    showRestElements() {
      const restos = this.insumoFilters.per_page * this.insumoFilters.page;
      if (restos <= this.insumoFilters.total_rows) return restos;
      else return restos - (restos - this.insumoFilters.total_rows);
    },
    firstElement(value) {
      return this.showRestElements() - value + 1;
    },
    async enviarSolicitud() {
      const { status } =
        await this.services.SolicitudCompra.updateSolicitudEstado(
          this.id_solicitud,
          {
            id_estado_solicitud: 1,
            id_solicitud_paso: 2,
          }
        );
      if (status == 204) {
        this.temporalAlert({
          show: true,
          message: "La solicitud ha sido enviada",
          type: "success",
        });
        this.$router.push("/proceso-solicitud-compra/list-solicitudes");
      }
    },
    async getObs() {
      const { status, data } =
        await this.services.SolicitudCompra.getSolicitudesObs();
      if (status === 200) {
        this.obs = data;
      }
    },
    updateObsFilter(val) {
      this.setOBSInsumoFilter(val);
      this.getInsumosList(this.id_solicitud);
    },
    async verDetalle(item) {
      const { status, data } =
        await this.services.SolicitudCompra.getSolicitudesCompraInsumo(item.id);
      if (status === 200) {
        this.setInsumoData(data);

        await this.obtenerProducto(item.id_presentacion);

        this.$router.push({
          name: "proveedor-insumo-detalle",
          params: { tipo: Number(this.$route.params.tipo) },
        });
      }
    },
  },
  computed: {
    ...mapState("agregarInsumo", [
      "id_solicitud",
      "insumosList",
      "insumoFilters",
      "fondos",
      "objetos_presupuestarios",
    ]),
    ...mapState(["selectedUnidad", "cargos"]),
    ...mapState("comprasModule", ["pasoSolicitud", "pasosSolicitudes"]),
    pageModel: {
      get() {
        return this.insumoFilters.page;
      },
      set(value) {
        this.setPage(value);
      },
    },
    perPageModel: {
      get() {
        return this.insumoFilters.per_page;
      },
      set(value) {
        this.setPerPage(value);
      },
    },
    permitirVerInsumo() {
      return (
        this.selectedUnidad?.cargo[0]?.id === this.cargos.tecnico_uaci ||
        this.selectedUnidad?.cargo[0]?.id === this.cargos.tecnico_ufi ||
        this.selectedUnidad?.cargo[0]?.id ===
        this.cargos.jefe_unidad_solicitante ||
        this.selectedUnidad?.cargo[0]?.id === this.cargos.jefe_uaci ||
        this.selectedUnidad?.cargo[0]?.id === this.cargos.jefe_ufi
      );
    },
    permitirAgregarEditarOEliminar() {
      if (
        this.selectedUnidad?.cargo[0]?.id === this.cargos.tecnico_unidad ||
        this.selectedUnidad?.cargo[0]?.id === this.cargos.tecnico_uaci ||
        this.selectedUnidad?.cargo[0]?.id === this.cargos.tecnico_ufi
      ) {
        return (
          this.pasoSolicitud?.id == this.pasosSolicitudes.borrador ||
          this.pasoSolicitud?.id == this.pasosSolicitudes.observacion ||
          this.pasoSolicitud?.id == this.pasosSolicitudes.observadoUFI ||
          this.pasoSolicitud?.id == this.pasosSolicitudes.observadoUACI ||
          this.pasoSolicitud == null
        );
      } else if (
        this.selectedUnidad?.cargo[0]?.id ===
        this.cargos.jefe_unidad_solicitante ||
        this.selectedUnidad?.cargo[0]?.id === this.cargos.jefe_ufi
      ) {
        return this.pasoSolicitud?.id == 2;
      } else {
        return false;
      }
    },
  },

  created() {
    if (this.id_solicitud) {
      this.Fondos = this.fondos;
      this.getObs();
    } else {
      this.$router.push({
        name: "list-solicitudes-compra",
      });
    }
  },
  beforeUpdate() {
    if (this.filtroObs.length === 0) {
      this.filtroObs = JSON.parse(JSON.stringify(this.insumosList));
    }
  },
  watch: {
    insumosList: {
      handler(e) {

        let contador_observaciones = 0

        for (let i of e) {
          let { id_estado } = i

          if (id_estado == 2 || id_estado == 5 || id_estado == 8) {
            contador_observaciones++
          }
        }

        this.$emit('insumosObservados', contador_observaciones)
      }
    }
  }
};
</script>
<style lang="scss" scoped>
#link-style {
  cursor: pointer;
  text-decoration: none;
}

:deep(.v-chip) {
  width: -webkit-fill-available !important;
  display: flex !important;
  justify-content: center !important;
}
</style>
