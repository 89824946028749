<template>
  <section>
    <v-row>
      <v-col cols="12" v-if="estadoProveedor == 1 || estadoProveedor == 4">
        <v-btn dark @click="documentosDialog = true" color="secondary">
          Agregar documento
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          no-data-text="No hay datos para mostrar"
          hide-default-footer
          :items-per-page="-1"
        >
          <template v-slot:[`item.fecha_carga`]="{ item }">
            {{ moment(item.fecha_carga).format("DD/MM/YYYY") }}
          </template>
          <template v-slot:[`item.acciones`]="{ item }">
            <v-btn @click="downloadProviderDoc(item)" icon
              ><v-icon>mdi-download</v-icon></v-btn
            >
            <v-btn
              v-if="estadoProveedor == 1 || estadoProveedor == 4"
              @click="(deleteProviderDoc = true), (doc_selected = item.id)"
              icon
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </template>
          <template v-slot:[`item.acciones_insumo`]="{ item }">
            <v-btn icon @click="downloadDoc(item)"
              ><v-icon>mdi-download</v-icon></v-btn
            >
            <v-btn
              v-if="pasoSolicitud?.id !== 4"
              @click="(deleteModal = true), (doc_selected = item.id)"
              icon
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </template>
        </v-data-table>
      </v-col>
      <dialogoConfirmacion
        :show="deleteModal"
        title="¿Desea eliminar este documento?"
        message="De confirmarse la siguiente acción, los cambios no serán reversibles."
        btnConfirmar="Aceptar"
        @close="deleteModal = false"
        @confirm="deleteDoc()"
      />
      <dialogoConfirmacion
        :show="deleteProviderDoc"
        title="¿Desea eliminar este documento?"
        message="De confirmarse la siguiente acción, los cambios no serán reversibles."
        btnConfirmar="Aceptar"
        @close="deleteProviderDoc = false"
        @confirm="deleteDocProvider()"
      />
      <!-- modal agregar documento -->
      <v-dialog
        persistent
        max-width="1000"
        class="modal"
        v-model="documentosDialog"
      >
        <v-card max-width="1000" max-height="1050">
          <v-btn
            @click="(documentosDialog = false), clearInfoArchivo()"
            icon
            color="secondary"
          >
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
          <v-card-text class="px-6 px-sm-10">
            <v-row no-gutters class="px-sm-10">
              <v-col cols="12" class="mb-3 text-center text-sm-start">
                <span class="text-h5 secondary--text">
                  Documentación de compra
                </span>
              </v-col>
              <v-col cols="12" class="my-1">
                <v-text-field
                  v-model="infoArchivo.nombreArchivo"
                  outlined
                  label="Nombre del archivo *"
                  @input="$v.infoArchivo.nombreArchivo.$touch()"
                  @blur="$v.infoArchivo.nombreArchivo.$touch()"
                  :error-messages="nombreArchivoError"
                  clearable
                  maxlength="50"
                />
              </v-col>
              <v-col cols="12">
                <v-file-input
                  v-model="infoArchivo.archivo"
                  outlined
                  prepend-icon=""
                  placeholder="Adjuntar archivo"
                  label="Archivo *"
                  @input="$v.infoArchivo.archivo.$touch()"
                  @blur="$v.infoArchivo.archivo.$touch()"
                  :error-messages="archivoError"
                  accept="application/pdf"
                  prepend-inner-icon="mdi-paperclip"
                />
              </v-col>
              <v-col cols="12" class="d-flex flex-column flex-sm-row">
                <v-btn
                  color="secondary"
                  class="text-capitalize mx-8 mb-2 mb-sm-0"
                  text
                  @click="clearInfoArchivo(), (documentosDialog = false)"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  color="secondary"
                  class="white--text mx-8 px-8 text-capitalize mt-2 mt-sm-0"
                  @click="addDocumento()"
                >
                  Agregar
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </section>
</template>

<script>
import { helpers, required } from "vuelidate/lib/validators";
import dialogoConfirmacion from "@/components/ConfirmationDialogComponent.vue";
import LegacyValidations from "@/utils/legacy-validations";
import { mapState } from "vuex";
const txtField = helpers.regex("txtArea", /^[A-Za-zñÑáéíóúÁÉÍÓÚ0-9\s]*$/);

function fileNameValid(value) {
  if (!value) {
    return true;
  }
  let extension = /(\.pdf)$/i;
  let file = value;
  return extension.test(file.name);
}

export default {
  name: "DocumentosCargadosComponent",
  components: {
    dialogoConfirmacion,
  },
  validations: {
    infoArchivo: {
      nombreArchivo: {
        required,
        txtField,
      },
      archivo: {
        required,
        fileNameValid,
        fileSizeValid: LegacyValidations.maxFileSizeRule(),
      },
    },
  },
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    infoArchivo: {
      nombreArchivo: null,
      archivo: null,
    },
    documentosDialog: false,
    deleteModal: false,
    doc_selected: null,
    deleteProviderDoc: false,
  }),
  computed: {
    ...mapState("comprasModule", ["pasoSolicitud"]),
    ...mapState("agregarInsumo", [
      "proveedorInfo",
      "id_solicitud",
      "insumosList",
    ]),
    insumosDescartados() {
      return this.insumosList.every(
        (target) => target.estado === "Descartado"
      );
    },
    nombreArchivoError() {
      const errors = [];
      if (!this.$v.infoArchivo.nombreArchivo.$dirty) return errors;
      !this.$v.infoArchivo.nombreArchivo.required &&
        errors.push("El nombre del archivo es requerido");
      !this.$v.infoArchivo.nombreArchivo.txtField &&
        errors.push("El nombre del archivo no es válido");
      return errors;
    },
    archivoError() {
      const errors = [];
      if (!this.$v.infoArchivo.archivo.$dirty) return errors;
      !this.$v.infoArchivo.archivo.required &&
        errors.push("El archivo es requerido");
      !this.$v.infoArchivo.archivo.fileNameValid &&
        errors.push("El archivo debe ser un PDF");
      !this.$v.infoArchivo.archivo.fileSizeValid && LegacyValidations.pushToErrorsArray(errors);
      return errors;
    },
    estadoProveedor() {
      const {
        estado_proveedor_web: { id },
      } = this.proveedorInfo;

      return id;
    },
  },
  methods: {
    clearInfoArchivo() {
      this.infoArchivo.nombreArchivo = null;
      this.infoArchivo.precioUnitario = null;
      this.infoArchivo.archivo = null;
      this.$v.$reset();
    },
    addDocumento() {
      this.$emit("saveDoc", this.infoArchivo);
      this.clearInfoArchivo();
      this.documentosDialog = false;
    },
    async deleteDoc() {
      const { status } =
        await this.services.SolicitudCompra.deleteSolicitudesInsumosDocs(
          this.doc_selected
        );
      if (status == 204) {
        this.temporalAlert({
          show: true,
          message: "Documento eliminado exitosamente",
          type: "success",
        });
        this.$emit("reloadDoc");
        this.deleteModal = false;
      }
    },
    async deleteDocProvider() {
      const { status } = await this.services.SolicitudCompra.deleteProveedorDoc(
        this.id_solicitud,
        this.proveedorInfo.id,
        this.doc_selected
      );
      if (status == 200) {
        this.temporalAlert({
          show: true,
          message: "Documento eliminado exitosamente",
          type: "success",
        });
        this.$emit("reloadDoc");
        this.deleteProviderDoc = false;
      }
    },
    async downloadDoc(item) {
      const response =
        await this.services.SolicitudCompra.getSolicitudesInsumosDoc(item.id);
      // Descargar el archivo
      const url = window.URL.createObjectURL(new Blob([response?.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${item.nombre}.pdf`);
      document.body.appendChild(link);
      link.click();
    },
    async downloadProviderDoc(item) {
      const response =
        await this.services.SolicitudCompra.getSolicitudesProveedorDoc(item.id);
      // Descargar el archivo
      const url = window.URL.createObjectURL(new Blob([response?.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${item.nombre}.pdf`);
      document.body.appendChild(link);
      link.click();
    },
  },
};
</script>
